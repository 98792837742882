import { Dialog, Fab, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';


const FeedbackFormFab = () => {

    const [feedbackOpen, setFeedbackOpen] = useState(false);

    return <>           
        <Dialog open={feedbackOpen} maxWidth="lg">
        <IconButton
          aria-label="close"
          onClick={() => setFeedbackOpen(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfA1OngSuvhVuKHuIAtnb4GG1XsixLcECiaYa6LVkl5vq1GwA/viewform?embedded=true" width="640" height="975" frameBorder="0">Loading…</iframe>
        </Dialog>
        <Fab color="primary" onClick={() => setFeedbackOpen(true)} style={{ position: "fixed", right: "20px", bottom: "20px", width: "75px", height: "75px"}}>
            <Stack direction="column" alignItems="center" justifyContent="center">
                <img src="/img/Feedback Icon.svg" width="40px" color="#fff"></img>
                <Typography sx={{ fontSize: "8px", textDecoration: "none", color: "#fff" }}>
                Feedback
                </Typography>
            </Stack>
        </Fab>
    </>
}

export default FeedbackFormFab;