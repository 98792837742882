import { Box, Container, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";
import MuiAppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import { AuthProvider } from "../contexts/auth";
import AuthenticatedContent from "./AuthenticatedContent";
import CompanySelection from "./CompanySelection";
import SearchInput from "./search/SearchInput";
import UserMenu from "./UserMenu";

const MainLayout: React.FC = () => {
  return (
    <AuthProvider>
      <AuthenticatedContent>
        <Container maxWidth="lg">
          <Box sx={{ my: 4 }}>
            <MuiAppBar>
              <Toolbar>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Link to={"/"}>
                    <img
                      src="/img/white_gold_landscape.svg"
                      width={"200px"}
                      style={{ margin: "15px 0px 0px 0px" }}
                      alt="Company HQ logo"
                    ></img>
                  </Link>
                  <SearchInput></SearchInput>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <CompanySelection></CompanySelection>
                    <UserMenu></UserMenu>
                  </div>
                </Stack>
              </Toolbar>
            </MuiAppBar>
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
              <Outlet />
            </Container>
          </Box>
        </Container>
      </AuthenticatedContent>
    </AuthProvider>
  );
};

export default MainLayout;
